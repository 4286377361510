<template>
    <section class="trabalhe-conosco">
        <slot></slot>
    </section>
</template>


<style lang="scss">
@import '@sass/global/mixins';
@import '@sass/global/functions';

.trabalhe-conosco {
    padding: size(0) 0;
    h2 {
        @media(max-width: 768px) {
            text-align: center;
        }
    }
    .btn-zap {
        color: #fff;
        padding: 20px;
        max-width: 260px;
        transition: 0.4s;
        margin-bottom: 12px;
        background-color: #85CA2D;

        @media(max-width: 768px) {
            margin: auto;
        }

        &:hover {
            background-color: #265ce9;
            transition: 0.4s cubic-bezier(0.13, 0.16, 0.92, 0.29);
        }
    }
    .conteudo-trabalhe {
        background-image: url('/img/_webp/backgrounds/banner-trabalhe-conosco.webp');
        padding: 50px 50px 0px 50px;
    }
    .feature {
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;
        align-items: center;
        justify-content: center;
        h4 {
            font-family: "Quicksand";
            font-style: normal;
            font-weight: 700;
            color: transparent;
            font-size: 150px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #f1eeee63;
            transform: translateX(40%);
            z-index: -1;
            @media (max-width: 767.98px) {
                transform: translateX(0);
                font-size: 100px;
            }
        }
        .img-wrapper {
            background-color: #265ce9;
            width: 109px;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 2;
        }
        p {
            width: 250px;
            font-family: "Quicksand";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 114%;
            color: #676767;
            @media (max-width: 575.98px) {
                font-size: 14px;
            }
        }
    }
}
</style>
