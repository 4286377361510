<template>
  <div class="textarea" :kind="kind" :class="kind ? kind : ''">
    <textarea :title="label" :name="name" :id="`textarea-${id}`" ref="textarea"></textarea>
    <label :for="`textarea-${id}`">{{label}}</label>
  </div>
</template>


<script setup>
  import { ref, onMounted } from "vue"

  const id = Math.ceil(Math.random() * 100000)
  const textarea = ref(null)

  defineProps({
    name: {
      default: '',
      type: String
    },

    label: {
      default: '',
      type: String
    },

    kind: {
      default: '',
      type: String
    },
  })

  onMounted(() => {
    const el = textarea.value

    el.addEventListener('input', () => {
      if (el.value !== '') {
        el.classList.add('active')
      }
      else {
        el.classList.remove('active')
      }
    })
  })
</script>


<style lang="scss">
  @import "@sass/global/functions";

  .textarea {
    position: relative;
    min-width: fit-content;
    display: inline-block;
    margin-bottom: 16px;
    margin-right: 8px;
    width: 100%;

    &.textarea-transparent {
      margin-bottom: 0px;
      margin-right: 0px;

      label {
        color: color(--white);
      }

      textarea {
        background-color: transparent;
        border-radius: size(0);
        border: 1px solid rgba(255, 255, 255, 0.363);
        color: color(--white);

          &:focus-visible {
            box-shadow: 0 0 0 2px color(--white);
          }

          &:focus, &.active {
          & + label {
            color: color(--white);
            background: rgba(255, 255, 255, 0);
            top: 1px;
            padding-top: 2px;
            left: 8px;
            transform: scale(0.8);
            max-width: 100%;
          }
        }
      }

      label {
        background-color: transparent;
      }
    }



    textarea {
      background: #fff;
      border-radius: size(8);
      border: none;
      padding: 24px 8px 8px;
      width: 100%;
      min-height: 56px;
      outline: none;
      //transition: .15s ease-in-out;
      box-shadow: 0 2px 4px rgba(#000, 15%);

      &:hover {
        box-shadow: 0 6px 15px -5px rgba(#000, 20%), inset 0 0 0 1px rgba(#000, 20%);
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px color(--blue-50);
      }

      &:focus, &.active {
        & + label {
          color: color(--blue-50);
          background: #fff;
          top: 1px;
          padding-top: 2px;
          left: 8px;
          transform: scale(0.8);
          max-width: 100%;
        }
      }
    }

    label {
      position: absolute;
      top: size(16);
      left: 8px;
      color: color(--grey-100);
      transform-origin: top left;
      transition: .2s ease-in-out;
      pointer-events: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      max-width: calc(100% - 16px);
    }
  }
</style>
