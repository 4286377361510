<template>
    <div class="swiper-slide">
        <slot></slot>
    </div>
</template>

<style lang="scss">
.swiper-slide {
    position: relative;
    background-position: center;
    background-size: cover;

    .banner-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50%;
        }
    }
}
</style>
