<template>
    <section class="solicite">
        <slot></slot>
    </section>
</template>


<style lang="scss">
@import '@sass/global/mixins';
@import '@sass/global/functions';

.solicite {
    padding: size(72) 0;
}
</style>
