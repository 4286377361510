<template>
  <div class="accordion" :id="`accordion-${id}`">
    <slot></slot>
  </div>
</template>


<style lang="scss">
  @import '@sass/global/mixins';
  @import '@sass/global/functions';

  .accordion {
    .accordion-header {
      margin-bottom: 0;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .accordion-item {
      background-color: transparent;
      margin-bottom: size(16);
      border-radius: size(4);

      &:first-of-type {
        .accordion-button {
          border-radius: 0;
        }
      }

      &:not(:first-of-type) {
        border-top: 0;
      }

      .accordion-button {
        background-color: #32a650;
        color: #fff;
        font-size: size(16);
        padding: size(16) size(24);
        box-sizing: border-box;

        // Accordion icon
        &::after {
          content: "";
          flex-shrink: 0;
          margin-left: auto;
          background-repeat: no-repeat;
          background-image: url(/img/icons/chevron-down.svg);
        }

        &:focus {
          z-index: 3;
          outline: 0;
        }

        &:not(.collapsed) {
          color: #fff;
          background-color: #32a650;
          box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
        }

        &:disabled {
          color: color(--grey-100);
          background-color: color(--grey-50);
          cursor: not-allowed;
        }
      }
    }

    .accordion-body {
      padding: size(32) size(24);
    }
  }
</style>


<script setup>
  const id = Math.ceil(Math.random() * 100000)
</script>
