<template>
    <div class="swiper swiperBanner">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <div class="swiper-button-prev swiper-banner-button-prev"></div>
        <div class="swiper-button-next swiper-banner-button-next"></div>
    </div>
</template>

<script setup>
    import Swiper, { Navigation, A11y, Autoplay } from 'swiper'
    import { onMounted } from 'vue'

    // Import Swiper styles
    import 'swiper/css'
    import 'swiper/css/navigation'

    onMounted(() => {
        const swiperBanner = new Swiper('.swiperBanner', {
            // configure Swiper to use modules
            autoplay: {
                delay: 4500,
            },
            modules: [Navigation, A11y, Autoplay],
            loop: true,
            navigation: {
                nextEl: '.swiper-banner-button-next',
                prevEl: '.swiper-banner-button-prev',
            },
            slidesPerView: 1,
        })
    })
</script>

<style lang="scss">
    @import '@sass/global/functions';

    .swiperBanner {
        width: 100%;
        height: 100%;
        aspect-ratio: 1360/580;
        top: 70px;
        margin-bottom: 70px;

        @media screen and (min-width: 992px) {
            top: 0;
            margin-bottom: 0;
        }

        .swiper-banner-button-prev,
        .swiper-banner-button-next {
            color: color(--white);

            @media (max-width: 575.98px) {
                top: 40%;
            }

            &::after {
                font-size: 40px;

                @media (max-width: 575.98px) {
                    font-size: 20px;
                }
            }
        }
    }
</style>
