<template>
    <section class="beneficios">
        <span id="beneficios"></span>

        <div class="container px-5">
            <h2 class="text-grey-300 text-center mb-5 text-uppercase">
                Benefícios
            </h2>

            <div class="row">
                <div class="col-12 col-md-12 col-lg-3">
                    <v-card-beneficios icon="desconto">
                        <h3>Vantagens exclusivas</h3>
                        <p>
                            <!-- Aproveite descontos incríveis durante toda vigência
                            de seu plano. -->

                            Valores que cabem no seu orçamento familiar durante
                            toda a vigência do plano. Qualidade e preço justo.
                        </p>
                    </v-card-beneficios>
                    <v-card-beneficios icon="credenciados">
                        <h3>Uma imensa rede de credenciados</h3>
                        <p>
                            Em nossa rede credenciada você conta com qualidade.
                        </p>
                    </v-card-beneficios>
                    <v-card-beneficios icon="planos">
                        <h3>Planos totalmente acessíveis</h3>
                        <p>
                            Nossos planos foram pensados respeitando o orçamento
                            da família brasileira, valores justos e acessíveis.
                        </p>
                    </v-card-beneficios>
                </div>

                <div
                    class="col-12 col-md-12 col-lg-6 d-flex justify-content-center"
                >
                    <v-img
                        src="/img/elements/cartao-beneficios.png"
                        width="600px"
                        class="img-element mb-5 mb-lg-0 z-index-1"
                    ></v-img>
                </div>

                <div class="col-12 col-md-12 col-lg-3">
                    <v-card-beneficios
                        icon="dependentes"
                        kind="card-revert"
                    >
                        <h3>Adição de muitos dependentes</h3>
                        <p>
                            A inclusão dos dependentes é feita com máximo
                            respeito e sem burocracia.
                        </p>
                    </v-card-beneficios>
                    <v-card-beneficios
                        icon="renovacao"
                        kind="card-revert"
                    >
                        <h3>Fácil renovação do pagamento</h3>
                        <p>
                            Renovação sem complicação, de forma automática, que
                            garante seu benefício a qualquer hora.
                        </p>
                    </v-card-beneficios>
                    <v-card-beneficios
                        icon="limites"
                        kind="card-revert"
                    >
                        <h3>Sem limite de uso na nossa rede</h3>
                        <p>
                            Por não ser um plano de saúde, você e sua família
                            podem usar o plano a qualquer momento, sem carência
                            e sem limite de uso.
                        </p>
                    </v-card-beneficios>
                </div>
            </div>

            <div class="d-flex mt-5 justify-content-center">
                <v-button onclick="window.location.assign('#plains')"
                    >Conheça os benefícios</v-button
                >
            </div>
        </div>
        <v-img
            src="/img/elements/img-bg-element-1.png"
            class="img-bg-element-1"
        ></v-img>
        <v-img
            src="/img/elements/img-bg-element-1.png"
            class="img-bg-element-2"
        ></v-img>
        <v-img
            src="/img/elements/img-bg-element-1.png"
            class="img-bg-element-3"
        ></v-img>
        <v-img
            src="/img/elements/img-bg-element-2.png"
            class="img-bg-element-4"
        ></v-img>
        <v-img
            src="/img/elements/img-bg-element-3.png"
            class="img-bg-element-5"
        ></v-img>
    </section>
</template>

<style scoped lang="scss">
    @import '@sass/global/mixins';
    @import '@sass/global/functions';

    .beneficios {
        padding: 12rem 1rem;
        overflow-x: hidden;
        position: relative;

        .z-index-1 {
            z-index: 1;
        }

        .img-bg-element-1 {
            position: absolute;
            bottom: 317px;
            left: -72px;
        }

        .img-bg-element-2 {
            position: absolute;
            bottom: 480px;
            right: -5%;
        }

        .img-bg-element-3 {
            position: absolute;
            bottom: 22px;
            right: 68%;
        }

        .img-bg-element-4 {
            position: absolute;
            top: 22px;
            right: 68%;
        }

        .img-bg-element-5 {
            position: absolute;
            top: -48px;
            right: 14%;
        }

        @media (max-width: 767.98px) {
            margin-top: -100px;
            .img-bg-element-1,
            .img-bg-element-2,
            .img-bg-element-3,
            .img-bg-element-4,
            .img-bg-element-5 {
                display: none;
            }
        }
    }
</style>
