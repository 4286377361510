<template>
    <section class="redes">
        <span id="redes"></span>

        <div class="container">
            <h2 class="text-grey-300">REDE CREDENCIADA</h2>

            <div class="tab-redes-container">
                <ul class="filters nav nav-pills">
                    <div
                        v-for="(categoria, index) in categorias"
                        :key="categoria"
                    >
                        <li class="nav-item">
                            <button
                                class="filter nav-link"
                                :class="index == 0 ? 'active' : null"
                                :data-filter="categoria.nome_slug"
                            >
                                {{ categoria.nome }}
                            </button>
                        </li>
                    </div>
                </ul>

                <div class="content">
                    <div class="projects">
                        <div class="d-flex flex-wrap">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="far fa-times-circle"></i></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mx-auto">
                            <div class="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                <v-img src="/img/logos/unigastro.png"></v-img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script setup>
    import { onMounted } from 'vue'

    const props = defineProps({
        categorias: String,
    })

    const doc = document
    const categorias = JSON.parse(props.categorias)

    onMounted(() => {
        const filters = doc.querySelectorAll('.filter')

        filters.forEach(filter => {
            filter.addEventListener('click', () => {
                let selectedFilter = filter.getAttribute('data-filter')
                let itemsToHide = doc.querySelectorAll(
                    `.projects .project:not([data-filter='${selectedFilter}'])`
                )
                let itemsToShow = doc.querySelectorAll(
                    `.projects [data-filter='${selectedFilter}']`
                )

                if (selectedFilter == 'all') {
                    itemsToHide = []
                    itemsToShow = doc.querySelectorAll(
                        '.projects [data-filter]'
                    )
                }

                itemsToHide.forEach(el => {
                    console.log(el)
                    el.classList.add('hide')
                    el.classList.remove('show')
                })

                itemsToShow.forEach(el => {
                    el.classList.remove('hide')
                    el.classList.add('show')
                })
            })
        })
    })
</script>

<style lang="scss">
    @import '@sass/global/mixins';
    @import '@sass/global/functions';

    .redes {
        padding: 5rem 1rem;

        @media (max-width: 600px) {
            margin-top: -100px;
        }

        .tab-redes-container {
            border: 1px solid #d7d7d7;

            .nav {
                padding: 2rem 1rem;
                border-bottom: 1px solid #d7d7d7;

                .nav-item {
                    white-space: nowrap;

                    .nav-link {
                        color: color(--grey-300);
                        padding: size(10);

                        @media (max-width: 991.98px){
                            padding: size(7);
                        }

                        &.active {
                            background-color: transparent;
                            font-weight: bold;
                        }

                        &:hover:not(.active) {
                            color: color(--blue-50);
                        }
                    }
                }
                @media (max-width: 991.98px){
                    display: flex;
                    justify-content: center;
                    padding: 2rem 0;
                }
            }

            .content {
                padding: 2rem 1rem;

                .project {
                    display: flex;
                    align-items: center;
                    max-height: 150px;
                    height: 140px;
                    width: 140px;
                }

                .btn {
                    cursor: unset;
                    &:focus {
                        box-shadow: none;
                    }

                    img {
                        width: 128px;
                    }
                }
            }
        }


    }
</style>
