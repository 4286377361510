<template>
    <footer>
        <div class="footer-header">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-6 mb-4 mb-lg-0">
                        <h2>Localização</h2>
                        <iframe
                            height="200px"
                            width="90%"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6312269871305!2d-48.4343437!3d-1.3977009999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92a48b1ebb87c89d%3A0xd3d361875dbe790d!2zQ2FydMOjbyBQYXLDoSBUb2Rvcw!5e0!3m2!1spt-BR!2sbr!4v1672141215953!5m2!1spt-BR!2sbr"
                            style="border: 0"
                            allowfullscreen
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 d-flex justify">
                        <div>
                            <h2>ONDE ESTAMOS?</h2>
                            <p>
                                Condomínio Marc Jacob - R. Treze de Maio, 191 -
                                Sala 305
                                <br />
                                Campina, Belém - PA, 66019-020
                            </p>
                            <a
                                href="https://api.whatsapp.com/send?phone=5591991972022"
                                target="_blank"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <img
                                            src="/img/icons/whatsapp.svg"
                                            width="26"
                                            height="26"
                                            alt="Ícone do WhatsApp"
                                        />
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <span>(91) 99197-2022</span>
                                    </div>
                                </div>
                            </a>
                            <a href="mailto:paratodoscard@gmail.com">
                                <div class="d-flex align-items-center mt-3">
                                    <div class="flex-shrink-0">
                                        <i
                                            style="font-size: 24px"
                                            class="fas fa-envelope"
                                        ></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <span> paratodoscard@gmail.com </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 d-flex justify">
                        <div>
                            <h2>REDES SOCIAIS</h2>

                            <a
                                href="https://www.instagram.com/meucartaoparatodos/"
                                target="_blank"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <img
                                            src="/img/icons/instagram.svg"
                                            alt="..."
                                        />
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <b>Instagram</b> <br />
                                        @Meucartaoparatodos
                                    </div>
                                </div>
                            </a>
                            <a
                                href="https://web.facebook.com/Cartaoparatodosbelem?mibextid=ZbWKwL&_rdc=1&_rdr"
                                target="_blank"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <img
                                            src="/img/icons/facebook.svg"
                                            alt="facebook"
                                        />
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <b>Facebook</b> <br />
                                        @Cartaoparatodosbelem
                                    </div>
                                </div>
                            </a>

                            <a
                                href="https://www.tiktok.com/@cartaoparatodosbelem"
                                target="_blank"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <iconify-icon
                                            icon="ic:outline-tiktok"
                                            style="
                                                color: #265ce9;
                                                font-size: 35px;
                                            "
                                        ></iconify-icon>
                                    </div>
                                    <div
                                        class="flex-grow-1"
                                        style="margin-left: 4px"
                                    >
                                        <b>TikTok</b> <br />
                                        @Cartaoparatodosbelem
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-developer">
            <div class="container">
                <div class="footer-content">
                    <div class="footer-content-span mb-5 mb-lg-0">
                        <div class="me-5 mb-3 mb-lg-0">
                            <span>Todos os direitos reservados.</span><br />

                            <span>Cartão Pará Todos 2022.</span><br />

                            <span>CNPJ: 45.894.991/0001-31</span>
                        </div>
                        <div>
                            <span>
                                <a
                                    class="color-link"
                                    href="/docs/Politica_de_privacidade.pdf"
                                    target="_blank"
                                >
                                    Política de Privacidade
                                </a>
                            </span>
                            <div>
                                <a
                                    class="color-link"
                                    href="/docs/Termos_de_uso.pdf"
                                    target="_blank"
                                >
                                    Termo de uso
                                </a>
                            </div>
                        </div>
                    </div>
                    <a
                        href="https://bredi.com.br/"
                        class="attribution"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                    >
                        Desenvolvido por Bredi
                        <v-icon
                            kind="bredi"
                            size="33px"
                            class="ms-3"
                        ></v-icon>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped lang="scss">
    /*   @import '@sass/global/mixins'; */
    @import '@sass/global/functions';

    footer {
        .footer-header {
            border-bottom: 2px solid #e5e5e5;
            padding: 10rem 1rem 5rem;

            h2 {
                margin-top: 16px;
                font-weight: 700;
                font-size: 20px;
                color: #7e7e7e;
            }
            p {
                font-weight: light;
                font-size: 16px;
                color: #7e7e7e;
            }
            a {
                color: #7e7e7e;

                span {
                    color: #878787;
                    font-weight: bold;
                }
            }
        }

        .footer-developer {
            padding: 4rem 1rem;
            color: color(--grey-200);

            .footer-content {
                display: flex;
                justify-content: space-between;

                @media (max-width: 991.98px) {
                    flex-direction: column;
                    align-items: center;
                }

                .footer-content-span {
                    display: flex;

                    @media (max-width: 991.98px) {
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                    }

                    .me-5 {
                        @media (max-width: 991.98px) {
                            width: 100%;
                            margin: 0 auto;
                        }
                    }
                }

                a {
                    color: color(--blue-300);

                    &:hover {
                        color: lighten(color(--blue-300), 10%);
                    }
                }

                .attribution {
                    color: color(--grey-200);

                    &:hover {
                        color: lighten(color(--grey-200), 10%);
                    }
                }
            }
        }
    }
</style>
