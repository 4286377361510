<template>
    <section
        class="contato"
        style="padding-top: 5rem; padding-bottom: 5rem"
    >
        <div class="container">
            <slot></slot>
        </div>
    </section>
</template>

<style lang="scss">
    @import '@sass/global/mixins';
    @import '@sass/global/functions';

    .contato {
        .container {
            background-image: linear-gradient(
                140deg,
                #d33f44,
                #290563,
                #1f42fa
            );
            padding: 50px 30px 0;

            @media screen and (max-width: 1440px) and (min-width: 992px) {
                height: 500px;
            }

            .contato-content {
                transform: translateY(0px);

                @include media(lg) {
                    transform: translateY(60px);
                }

                p {
                    max-width: 550px;
                }

                .img-element {
                    margin-top: 11rem;

                    @media screen and (max-width: 1440px) and (min-width: 992px) {
                        margin-top: 0rem;
                        max-width: 270px;
                    }

                    @media screen and (max-width: 992px) and (min-width: 300px) {
                        max-width: 200px;
                        margin-top: 2rem;
                        display: none;
                    }
                }

                ul {
                    margin-top: 12px;
                    padding-left: 8px;
                    li {
                        margin-bottom: 12px;
                        a {
                            color: #fff;
                            font-size: 1.6rem;
                            transition: all 0.4s;

                            &:hover {
                                color: #3eb0dd;
                                transition: all 0.4s;
                            }

                            i {
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }

            .contato-form {
                transform: translateY(35px);

                form {
                    display: flex;
                    flex-direction: column;

                    .button-margin {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
</style>
