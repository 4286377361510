<template>
    <div class="swiper-blog-slide swiper-slide">
        <div class="slide-wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
    .swiper-blog-slide {
        /* outline: 1px solid red; */
        /* margin: 0 1.25rem 0 1.25rem; */
        /* width: 30rem; */
        background-color: white;
        text-align: start;
    }
    .swiper-slide {
        background: transparent !important;
        transition: all 0.2s ease-in-out;
    }
</style>
