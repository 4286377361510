<template>
  <div class="swiper swiperMobileTable">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <div class="swiper-button-prev swiper-mobile-table-button-prev"></div>
    <div class="swiper-button-next swiper-mobile-table-button-next"></div>
  </div>
</template>

<script setup>
import Swiper, { Navigation } from 'swiper'
import { onMounted } from 'vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

onMounted(() => {
  const swiperMobileTable = new Swiper('.swiperMobileTable', {
    // configure Swiper to use modules

    modules: [Navigation],
    loop: true,
    navigation: {
      nextEl: '.swiper-mobile-table-button-next',
      prevEl: '.swiper-mobile-table-button-prev',
    },
    slidesPerView: 1,
  })
})
</script>

<style lang="scss" scoped>
@import '@sass/global/functions';

.swiperMobileTable {
  width: 100%;
  height: auto;
  position: relative;

  .swiper-mobile-table-button-prev,
  .swiper-mobile-table-button-next {
    color: #676767;
    top: 10%;
  }

  .swiper-mobile-table-button-prev::after,
  .swiper-mobile-table-button-next::after {
    font-size: 26px;
  }
}
</style>
