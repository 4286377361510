<template>
    <section class="empresa">
        <div class="container">
            <h2>Missão, visão e valores.</h2>

            <div class="row empresa-content">
                <div class="col-sm mt-5">
                    <img
                        src="/img/elements/familia.png"
                        alt="#"
                        class="img-fluid family"
                    />
                </div>

                <div class="col-sm col-md card-item mt-5">
                    <div class="d-flex">
                        <img
                            src="/img/elements/target.png"
                            alt="#"
                        />
                        <h4>missão</h4>
                    </div>
                    <p class="mt-3">
                        Ser a empresa de maior credibilidade do mercado,
                        oferecendo as melhores soluções aos nossos clientes.
                    </p>
                </div>
                <div class="col-sm col-md card-item mt-5">
                    <div class="d-flex">
                        <img
                            src="/img/elements/mountain.png"
                            alt="#"
                        />
                        <h4>Visão</h4>
                    </div>
                    <p class="mt-3">
                        Ser a maior e melhor rede de cartões, oferecendo aos
                        seus clientes e parceiros uma relação duradoura, com
                        foco no crescimento, rentabilidade e responsabilidade
                        socioambiental.
                    </p>
                </div>
                <div class="col-sm col-md card-item mt-5">
                    <div class="d-flex">
                        <img
                            src="/img/elements/values.png"
                            alt="#"
                        />
                        <h4>Valores</h4>
                    </div>
                    <p class="mt-3">
                        Pessoas, saúde, ética, responsabilidade e
                        comprometimento com o cliente.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
    @import '@sass/global/mixins';
    @import '@sass/global/functions';

    .empresa {
        .container {
            padding: 130px 0 0 0;

            @media screen and (max-width: 1440px) and (min-width: 992px) {
                height: 500px;
            }

            @media screen and (max-width: 568px) {
                padding: 130px 0px 0 25px;

                h2 {
                    font-size: 2.6rem;
                    margin-bottom: 3rem;
                }
            }

            h2 {
                color: #7e7e7e;
            }

            .empresa-content {
                transform: translateY(0px);

                .family {
                    width: 300px;
                }

                @include media(lg) {
                    transform: translateY(60px);
                }

                p {
                    max-width: 550px;
                    font-size: 14px;
                }
                h4 {
                    font-size: 20px;
                    color: #7e7e7e;
                }

                .card-item {
                    img {
                        width: 50px;
                    }

                    .d-flex {
                        align-items: center;
                        gap: 1rem;
                    }
                }
            }
        }
    }
</style>
