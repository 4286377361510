<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="`panel-heading-${id}`">
        <button
          :disabled="disabled"
          type="button"
          class="accordion-button"
          :class="(closed === false) ? '' : 'collapsed'"
          data-bs-toggle="collapse"
          :data-bs-target="`#panel-collapse-${id}`"
          :aria-expanded="(closed === false) ? 'true' : 'false'"
          :aria-controls="`panel-collapse-${id}`">
            {{ title }}
        </button>
    </h2>

    <div
      :id="`panel-collapse-${id}`"
      class="accordion-collapse collapse"
      :class="(closed === false) ? 'show' : ''"
      :aria-labelledby="`panel-heading-${id}`">

      <div class="accordion-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>


<script setup>
  const id = Math.ceil(Math.random() * 100000)

  defineProps({
    title: {
      type: String,
      default: 'Sem título',
    },

    closed: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  })
</script>
