<template>
    <div class="swiper-slide">
        <slot></slot>
    </div>
</template>


<style lang="scss">
@import '@sass/global/functions';


.swiper-slide {
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: #F1F1F1;
    align-items: baseline !important;
    flex-direction: column;
    justify-content: center;

    .text-bronze {
        color: #B1510F;
    }

    .text-prata {
        color: #9598A0;
    }

    .text-gold {
        color: #D7A700;
    }

    .text-empresarial {
        color: #676767;
    }

    .text-grupo {
        color: #00397B;
    }

    .table-header {
        margin-top: 5rem;
        padding: 0 4rem;
        width: inherit;

        h3 {
            color: color(--grey-300);
            font-size: 27px;
            font-weight: 500;
        }

        .table-header-subtitle {
            font-size: 22px;
            color: color(--grey-300);
            font-weight: 600;
            margin-top: 2rem;
            line-height: 24px;
        }

        .information-title {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-top: 2rem;

            &::before {
                position: absolute;
                content: "";
                height: 2px;
                width: 20px;
                background-color: #cdcccc;
                top: -3px;
            }

            &::after {
                position: absolute;
                content: "";
                height: 2px;
                width: 20px;
                background-color: #cdcccc;
                bottom: -5px;
            }

            i {
                margin-right: 5px;
                color: color(--green-btn);
            }

            p {
                margin-bottom: 0;
                color: color(--green-btn);
                font-weight: 600;
                font-size: 18px;
            }

        }
    }

    .table-content {
        width: 100%;
        padding: 0 4rem;
        margin-top: 3rem;
        margin-bottom: 3rem;

        .table-card {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            background-color: color(--white);
            padding: 10px;
            border: 1px solid #e0e0e0;


            i {
                color: color(--green-btn);
                margin-right: 10px;
                font-size: 30px;
            }

            img {
                width: 61px;
                margin-right: 10px;
            }

            .tatble-card-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .title {
                    font-size: 20px;
                    margin-bottom: 0;
                    font-weight: 600;
                }

                .subtitle {
                    font-size: 18px;
                    margin-bottom: 0;
                    font-weight: 600;
                }
            }
        }

        .table-card.table-card-filter-gray {
            background-color: #F1F1F1;

            img {
                filter: grayscale(100%);
            }

            i {
                opacity: 0;
            }

            .tatble-card-title {
                color: #ADADAD;
            }
        }

        .btn-contratar-plano {
            width: 100%;
            background-color: #79BA5B;
            color: white;
            font-family: "Quicksand";
            border: none;
            padding: 10px;
            font-weight: 700;
            border: 1px solid #D9D9D9;
            transition: all 0.3s ease-in-out;
            margin-top: 2rem;

            &:hover {
                background-color: #3f7625;
            }
        }

        .table-aviso {
            background-color: white;
            padding: 5px;
            margin-top: 2rem;

            p {
                font-weight: 600;
                font-size: 20px;
                color: color(--grey-300);
            }
        }
    }
}
</style>