<template>
    <div
        class="cookie"
        ref="cookieBox"
    >
        <div class="d-md-flex">
            <p class="content">
                Nós usamos cookies e outras tecnologias semelhantes para
                melhorar a sua experiência em nosso site e recomendar conteúdo
                de seu interesse. Ao acessar o nosso site, você concorda com tal
                monitoramento.

                <a
                    href="/docs/Politica_de_privacidade.pdf"
                    target="_blank"
                >
                    Política de privacidade.
                </a>
            </p>

            <div class="d-flex d-md-block ps-0 ps-md-4 pt-5 pt-md-0">
                <v-button
                    @click="acceptCookies"
                    type="button"
                    class="me-3 me-md-0"
                    kind="theme"
                >
                    Aceitar
                </v-button>

                <v-button
                    @click="declineCookies"
                    type="button"
                    class="text-white"
                    kind="flat"
                >
                    Recusar
                </v-button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue'

    const cookieBox = ref(null)

    onMounted(() => {
        let cookieAccepted = localStorage.getItem('cookie')

        if (cookieAccepted === 'true') {
            cookieBox.value.style.display = 'none'
        }

        if (cookieAccepted === 'false' || !cookieAccepted) {
            cookieBox.value.style.display = ''
        }
    })

    function acceptCookies() {
        hideCookieBox({ cookie: true })
    }

    function declineCookies() {
        hideCookieBox({ cookie: false })
    }

    function hideCookieBox({ cookie }) {
        cookieBox.value.classList.add('hide')

        localStorage.setItem('cookie', cookie)

        setTimeout(() => {
            cookieBox.value.style.display = 'none'
        }, 300)
    }
</script>

<style lang="scss" scoped>
    @import '@sass/global/functions';

    .cookie {
        position: fixed;
        max-width: 90%;
        max-width: 600px;
        bottom: 20px;
        left: 50%;
        padding: 12px 16px;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        z-index: 100;
        transition: all 0.3s;
        box-sizing: content-box;

        > div {
            margin: 0 4px;
        }

        .btn {
            margin-bottom: 5px;
            font-family: 'Gilroy', sans-serif;
            padding: 0.4rem 1rem;
            text-transform: unset;
            font-size: 1.4rem;
            width: 100%;
            width: stretch;
        }

        .btn-aceitar {
            background-color: color(--blue-300);
            color: #fff;

            &:hover {
                background-color: lighten(color(--blue-300), 10%);
            }
        }

        &.hide {
            transform: translate(-50%, 200%);
            opacity: 0;
        }

        .content {
            font-size: 1.3rem;

            a {
                color: #85a7ff;

                &:hover {
                    color: lighten(#85a7ff, 10%);
                }
            }
        }
    }
</style>
