import mitt from 'mitt' // Import mitt
import { createApp } from 'vue'

const emitter = mitt() // Initialize mitt
export default emitter // Export mitt

const app = createApp()

app.component('v-button', require('@components/Button.vue').default)
    .component('v-a', require('@components/Link.vue').default)
    .component('v-logo', require('@components/Logo.vue').default)
    .component('v-img', require('@components/Image.vue').default)
    .component('v-icon', require('@components/Icon.vue').default)
    .component('v-footer', require('@components/Footer.vue').default)
    .component('v-input', require('@components/Input.vue').default)
    .component('v-textarea', require('@components/Textarea.vue').default)
    .component('v-header', require('@components/Header.vue').default)
    .component(
        'v-cookie-consent',
        require('@components/CookieConsent.vue').default
    )
    // .component(
    //     'v-modal-teste',
    //     require('@components/ModalHome.vue').default
    // )

    .component(
        'v-swiper-banner',
        require('@components/Swiper/SwiperBanner.vue').default
    )
    .component(
        'v-swiper-banner-slide',
        require('@components/Swiper/SwiperBannerSlide.vue').default
    )
    .component(
        'v-swiper-blog',
        require('@components/Swiper/SwiperBlog.vue').default
    )
    .component(
        'v-swiper-blog-slide',
        require('@components/Swiper/SwiperBlogSlide.vue').default
    )
    .component(
        'v-swiper-mobile-table',
        require('@components/Swiper/SwiperMobileTable.vue').default
    )
    .component(
        'v-swiper-mobile-table-slide',
        require('@components/Swiper/SwiperMobileTableSlide.vue').default
    )

    .component('v-select', require('@components/Select/Select.vue').default)
    .component(
        'v-option',
        require('@components/Select/SelectOption.vue').default
    )

    .component('v-card', require('@components/Card/Card.vue').default)
    .component(
        'v-card-beneficios',
        require('@components/Card/Card-beneficios.vue').default
    )

    .component('v-table', require('@components/Table/Table.vue').default)
    .component('v-tr', require('@components/Table/Tr.vue').default)
    .component('v-th', require('@components/Table/Th.vue').default)
    .component('v-td', require('@components/Table/Td.vue').default)

    .component(
        'v-accordion',
        require('@components/Accordion/Accordion.vue').default
    )
    .component(
        'v-accordion-item',
        require('@components/Accordion/AccordionItem.vue').default
    )

    .component(
        'v-section-contato',
        require('@components/Sections/Contato.vue').default
    )
    .component(
        'v-section-beneficios',
        require('@components/Sections/Beneficios.vue').default
    )
    .component(
        'v-section-cartao',
        require('@components/Sections/Cartao.vue').default
    )
    .component(
        'v-section-parcerias',
        require('@components/Sections/Parcerias.vue').default
    )
    .component(
        'v-section-redes',
        require('@components/Sections/Redes.vue').default
    )
    .component(
        'v-section-solicite',
        require('@components/Sections/Solicite.vue').default
    )
    .component(
        'v-section-trabalhe-conosco',
        require('@components/Sections/TrabalheConosco.vue').default
    )

    .component(
        'v-section-empresa',
        require('@components/Sections/Empresa.vue').default
    )

    .component(
        'v-modal-redes',
        require('@components/Modal/modalRedes.vue').default
    )

    .component('v-map', require('@components/Map.vue').default)

    .provide('emitter', emitter) // ✅ Provide as `emitter`
    .mount('#app')
