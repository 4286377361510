<template>
    <header>
        <nav
            class="navbar navbar-expand-lg navbar-light"
            ref="navbar"
        >
            <div class="container">
                <a
                    class="navbar-brand"
                    href="/"
                >
                    <v-img
                        src="/img/logos/logo-cartao-para-todos.png"
                        class="img-brand"
                        loading="eager"
                    ></v-img>
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div
                    class="collapse navbar-collapse justify-content-end"
                    id="navbarNavDropdown"
                >
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/#beneficios"
                                >BENEFÍCIOS</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                aria-current="page"
                                href="/#cartao"
                                >O CARTÃO</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/#redes"
                                >REDE CREDENCIADA</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/#mapa"
                                >MAPA</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/#contratar"
                                >COMO CONTRATAR?</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link d-none d-md-block"
                                href="/#plains"
                                onclick="location.href='#plains'"
                                >PLANOS</a
                            >

                            <a
                                onclick="location.href='#plains'"
                                class="nav-link d-block d-md-none"
                                href="/#plainsMobile"
                                >PLANOS</a
                            >
                        </li>

                        <!-- <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/blog/#blog"
                                >BLOG</a
                            >
                        </li> -->

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/#parcerias"
                                >SEJA UM PARCEIRO</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/#trabalheConosco"
                                >TRABALHE CONOSCO</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="/#contato"
                                >CONTATO</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script setup>
    import { onMounted, ref } from 'vue'

    const navbar = ref(null)
    onMounted(() => {
        const path = location.pathname
        const nav = navbar.value
        if (window.innerWidth > 992) {
            document.addEventListener('scroll', () => {
                const scrollY = window.scrollY
                if (scrollY > 100) {
                    nav.classList.add('bg-bege')
                } else {
                    nav.classList.remove('bg-bege')
                }
            })
        }

        if (path.match('empresa')) {
            navbar.value.classList.add('nav-empresa')
        }
    })
</script>

<style lang="scss" scoped>
    @import '@sass/global/functions';

    ul {
        list-style: none;
    }

    .navbar {
        width: 100%;
        position: fixed;
        z-index: 100;
        transition: all 0.3s ease-in-out;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // gradient
            background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0) 0%,
                rgb(0 0 0 / 18%) 100%
            );
            z-index: -1;
        }

        @media (max-width: 991.98px) {
            background-color: color(--white);
            box-shadow: 1px 7px 14px -4px rgb(0 0 0 / 18%);
        }

        .navbar-brand {
            @media (max-width: 1200px) {
                width: 120px;
            }

            .img-brand {
                @media (max-width: 424.98px) {
                    width: 150px;
                }
            }
        }

        &.nav-empresa {
            border-bottom: 2px solid #e5e5e5;
            .nav-item {
                .nav-link {
                    color: #898888;
                }
            }
        }

        .nav-item {
            @media (max-width: 991.98px) {
                text-align: end;
            }

            .nav-link {
                color: color(--white);
                font-weight: bold;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: color(--blue-50);
                }

                @media (max-width: 2600px) {
                    font-size: 14px;
                }

                @media (max-width: 1500px) {
                    font-size: 11px;
                }

                @media (max-width: 1350px) {
                    font-size: 10px;
                }

                @media (max-width: 991.98px) {
                    color: color(--grey-100);
                    font-size: 12px;
                }
            }

            &:not(:last-child) {
                margin-right: 1rem;

                @media (max-width: 992px) {
                    margin: 0;
                }
            }
        }
    }

    .bg-bege {
        background-color: color(--white);
        box-shadow: 1px 7px 14px -4px rgb(0 0 0 / 18%);

        .nav-link {
            color: #0085c9 !important;
        }

        &::before {
            background: none;
        }
    }
</style>
