<template>
    <section class="parcerias">
        <slot></slot>
    </section>
</template>


<style lang="scss">
@import '@sass/global/mixins';
@import '@sass/global/functions';

.parcerias {
    padding: size(72) 0;
    background-color: color(--blue);


    .nav {
        margin-bottom: 2rem;
        flex-wrap: nowrap;

        @media (max-width: 991.98px) {
            flex-direction: column;
        }

        .nav-item {
            width: 50%;

            @media (max-width: 991.98px) {
                width: 100%;
            }

            .nav-link {
                width: 100%;
                border-radius: 0;
                color: color(--white);
                border: 1px solid #6184E0;
            }
        }
    }
}
</style>




<script>
import { Navigation, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

// Import Swiper styles
export default {

    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Scrollbar, A11y, EffectFade],
        };
    },
};
</script>
