<template>
  <div class="dropdown-wrapper">
    <div :id="`dropdown-id-${id}`" :title="label" class="dropdown" tabindex="0">
      <label>
        {{label}}
      </label>

      <div class="content" :class="label === '' ? 'no-label' : ''">
        <div class="label">
          {{value}}
        </div>

        <div class="arrow">
          <i class="fas fa-chevron-down"></i>
        </div>
      </div>

      <div class="options">
        <slot ref="options"></slot>
      </div>

      <select :name="name"></select>
    </div>
  </div>
</template>


<script setup>
  import { onMounted } from 'vue'

  const doc = document
  const id = Math.ceil(Math.random() * 100000)

  defineProps({
    name: {
      default: '',
      type: String
    },

    value: {
      default: 'Selecione',
      type: String
    },

    label: {
      default: '',
      type: String
    },
  })



  onMounted(() => {
    const dropdown = {
      current: null,

      init: props => {
        const trigger = (props.element === undefined) ? '.dropdown' : props.element

        const $dropdowns = doc.querySelectorAll(trigger)

        $dropdowns.forEach(dropdown => {
          const $selectElement = dropdown.querySelector('select')

          const $emptyOptionElement = doc.createElement('option')
          $emptyOptionElement.innerHTML = ''
          $emptyOptionElement.setAttribute('disabled', true)
          $emptyOptionElement.setAttribute('selected', true)

          $selectElement.appendChild($emptyOptionElement)

          dropdown.querySelectorAll('.option').forEach(option => {
            // Create select options based on content
            const $optionElement = doc.createElement('option')

            $optionElement.value = option.getAttribute('data-value')
            $optionElement.innerHTML = option.innerHTML

            $selectElement.appendChild($optionElement)
          })
        })


        doc.addEventListener('click', e => {
          e.stopPropagation()

          if (e.target.matches(trigger)) {
            dropdown.current = e.target.closest(trigger)

            const $currentDropdown = dropdown.current
            const $contentLabel = $currentDropdown.querySelector('.content .label')
            const $optionsBox = $currentDropdown.querySelector('.options')
            const $options = $optionsBox.querySelectorAll('.option')
            const $select = $currentDropdown.querySelector('select')

            if (props.alignBottom === true) {
              $optionsBox.style.top = '100%'
            }

            dropdown.open($currentDropdown)

            if (props !== undefined) {
              if (props.closeOnSelect === true) {
                $options.forEach(option => {
                  option.addEventListener('mouseup', () => {
                    $select.value = option.dataset.value

                    $contentLabel.innerText = option.innerText

                    dropdown.close()
                  })
                })
              }
            }

            // Callback function call
            if (props.onOpen !== undefined) {
              props.onOpen()
            }
          }
          else {
            dropdown.close('force')
          }
        })
      },


      open: selector => {
        const $optionsBoxes = doc.querySelectorAll('.dropdown .options')

        $optionsBoxes.forEach(optionsBox => {
          optionsBox.classList.remove('current')
        })

        dropdown.current = selector

        const element = selector.querySelector('.options')

        element.style.display = 'block'

        setTimeout(() => {
          element.classList.add('show', 'current')

          dropdown.close()
        })
      },


      /**
        * Close dropdown
        * @param {String} force - Force close
        */
      close: (force = undefined) => {
        const $optionsBoxes = doc.querySelectorAll('.dropdown .options')

        $optionsBoxes.forEach(optionsBox => {
          if (!optionsBox.classList.contains('current') || force === 'force') {
            optionsBox.classList.remove('show')

            setTimeout(() => {
              optionsBox.style.display = ''

              dropdown.current = null
            }, 200)
          }
        })
      }
    }


    // Initialize our dropdown
    dropdown.init({
      element: '.dropdown',
      closeOnSelect: true,
      alignBottom: false,
      onOpen: () => {
        // Your callback function
      },
    })
  })
</script>


<style lang="scss">
  @import "@sass/global/functions";

  .dropdown-wrapper {
    position: relative;
    width: fit-content;
    max-width: 100%;
    display: inline-block;
    margin-bottom: size(16);
    margin-right: 8px;

    .dropdown {
      display: inline-block;
      position: relative;
      width: 100%;
      min-width: 198px;
      background-color: #fff;
      border-radius: size(8);
      border: none;
      padding: size(16);
      outline: none;
      transition: .2s ease-in-out;
      box-shadow: 0 2px size(4) rgba(#000, 15%);
      user-select: none;
      cursor: pointer;

      &:hover, &:focus {
        box-shadow: 0 6px 15px -5px rgba(#000, 20%), inset 0 0 0 1px rgba(#000, 20%);
      }

      &:focus-visible {
        box-shadow: 0 6px 15px -5px rgba(#000, 20%), 0 0 0 1px color(--blue-50);
      }

      select {
        display: none;
      }

      label {
        position: absolute;
        top: size(4);
        left: size(16);
        color: color(--grey-300);
        transform-origin: top left;
        transition: 0.2s ease-in-out;
        transform: scale(0.8);
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(106% - 30px);
        overflow: hidden;
        pointer-events: none;
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        pointer-events: none;
        position: relative;
        width: 100%;

        &.no-label {
          .label {
            top: -8px;
          }

          .arrow {
            top: -2px;
          }
        }

        .label {
          position: relative;
          width: 100%;
          margin-top: 8px;
          margin-bottom: -8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .arrow {
          display: flex;
          position: relative;
          align-items: flex-end;
          margin-left: 8px;
          color: color(--grey-300);
        }
      }


      .options {
        min-width: 100%;
        max-width: 90vw;
        width: max-content;
        position: absolute;
        background-color: #fff;
        top: 0%;
        right: 0%;
        opacity: 0;
        transform: scale(0.8);
        transform-origin: top right;
        transition: 0.2s cubic-bezier(0.41, -0.16, 0.33, 1.39);
        box-shadow: none;
        border-radius: 10px;
        display: none;
        overflow: hidden;
        cursor: auto;
        z-index: 10;

        .option {
          color: #000;
          display: block;
          font-size: 1.6rem;
          padding: 12px size(16);
          text-decoration: none;
          cursor: default;

          &:hover {
            background-color: rgba(#000, 15%);
          }
        }

        &.show {
          box-shadow: 5px 17px 30px -7px #0000003b;
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

</style>
