<template>
  <div class="b:card">
    <slot></slot>
  </div>
</template>


<style scoped lang="scss">
  .b\:card {
    position: relative;
    border: 0;
    box-shadow: 0px 2px 4px rgba(#000, 15%);
    padding: 28px 24px;
  }
</style>
