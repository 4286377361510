<template>
  <div class="input" :kind="kind" :class="kind ? kind : ''" :mask="mask">
    <input :class="mask ? `mask-${mask}` : ''" :title="label" :type="type" :autocomplete="autocomplete" :name="name" :id="`input-${id}`" :required="required" ref="input"
      @focus.prevent="$event.target.select()">
    <label :for="`input-${id}`">{{ label }}</label>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import * as VMasker from 'vanilla-masker'

const id = Math.ceil(Math.random() * 100000)
const input = ref(null)

const props = defineProps({
  name: {
    default: '',
    type: String
  },

  label: {
    default: '',
    type: String
  },

  autocomplete: {
    default: 'off',
    type: String
  },

  type: {
    default: 'text',
    type: String
  },

  kind: {
    default: '',
    type: String
  },

  mask: {
    default: '',
    type: String
  },

  required: {
    default: false,
    type: Boolean
  }
})

onMounted(() => {
  const el = input.value

  /**
    * Máscaras de input
    */
  const masks = {
    cpf: '999.999.999-99',
    cnpj: '99.999.999/9999-99',
    phone: '(99) 99999-9999',
  }

  if (props.mask) {
    VMasker(el).maskPattern(masks[props.mask])
  }

  el.addEventListener('input', () => {
    if (el.value !== '') {
        el.classList.add('active')
    }
    else {
        el.classList.remove('active')
    }
  })
})
</script>


<style lang="scss">
@import "@sass/global/functions";

.input {
  position: relative;
  width: fit-content;
  display: inline-block;
  margin-bottom: 16px;
  margin-right: 8px;
  width: 100%;

  &.input-transparent {
    width: 100%;

    input {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.363);
      color: color(--white);

      &:focus-visible {
        box-shadow: 0 0 0 2px color(--white);
      }

      &:focus,
      &.active {
        &+label {
          color: color(--white);
        }
      }
    }

    label {
      color: color(--white);
    }
  }

  &.input-grey {
    width: 100%;

    input {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.363);
      color: color(--grey-100);

      &:focus-visible {
        box-shadow: 0 0 0 2px color(--grey-100);
      }

      &:focus,
      &.active {
        &+label {
          color: color(--grey-100);
        }
      }
    }

    label {
      color: color(--grey-100);
    }
  }

  input {
    background: #fff;
    border: none;
    padding: 24px 8px 8px;
    width: 100%;
    outline: none;
    transition: .15s ease-in-out;
    box-shadow: 0 2px 4px rgba(#000, 15%);

    &:hover {
      box-shadow: 0 6px 15px -5px rgba(#000, 20%), inset 0 0 0 1px rgba(#000, 20%);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px color(--blue-50);
    }

    &:focus,
    &.active {
      &+label {
        color: color(--blue-50);
        top: 2px;
        left: 8px;
        transform: scale(0.8);
        max-width: 100%;
      }
    }


  }

  label {
    position: absolute;
    top: size(16);
    left: 8px;
    color: color(--grey-100);
    transform-origin: top left;
    transition: .2s ease-in-out;
    pointer-events: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: calc(100% - 16px);
  }
}
</style>
