<template>
    <section class="cartao">
        <span id="cartao"></span>

        <div class="container">
            <div class="row justify-content-center justify-content-lg-start">
                <div class="col-12 col-md-12 col-lg-4 col-xl-4">
                    <div class="contato-content">
                        <h2 class="text-grey-300 mb-5 text-uppercase">
                            O Cartão
                        </h2>
                        <p>
                            Em 2022 a empresa Cartão Pará Todos foi criada com o
                            objetivo de viabilizar o acesso à saúde, lazer e
                            educação para a população paraense. Não vendemos
                            apenas um cartão de benefícios, vendemos
                            acessibilidade, oportunidade e novas experiências
                            para os beneficiários! Adquirindo o Cartão Pará
                            Todos você terá acesso aos serviços de renomadas
                            empresas com
                            <strong>vantagens exclusivas.</strong> O único
                            genuinamente paraense.
                        </p>
                    </div>

                    <div class="d-flex mt-5 mb-md-5 mb-sm-5">
                        <v-button
                            ><a
                                href="/empresa"
                                class="text-white"
                            >
                                Saiba mais</a
                            >
                        </v-button>
                    </div>
                </div>

                <div class="col-10 col-md-6 col-lg-5 col-xl-4">
                    <div class="box-beneficios">
                        <h3 class="text-white">
                            A melhor rede referenciada do estado do Pará em consultas, laboratórios, farmácias,
                            academias e muito mais...
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <v-img
            src="/img/elements/familia-cartao.webp"
            class="img-bg-element-1 d-none d-lg-block"
        ></v-img>
    </section>
</template>

<style scoped lang="scss">
    @import '@sass/global/mixins';
    @import '@sass/global/functions';

    .cartao {
        padding: size(56) size(24);
        position: relative;
        overflow-x: hidden;

        @include media(md) {
            padding: size(80) size(16);
            margin-top: 100px;
        }

        @include media(lg) {
            margin-top: 0;
        }

        .box-beneficios {
            background-image: linear-gradient(180deg, #4556e7, #2731ba);
            padding: 20px;
            height: 295px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            @include media(md) {
                height: 100%;
            }

            @include media(xl) {
                height: 70%;
            }

            @include media(xxl) {
                height: 70%;
            }

            @media screen and (max-width: 1200px) and (min-width: 1000px) {
                height: 70%;
            }

            h3 {
                font-size: 3rem;

                @media (max-width: 575.98px) {
                    font-size: 2rem;
                }
            }
        }

        .img-bg-element-1 {
            position: absolute;
            bottom: 229px;
            left: 71%;
            width: 46%;

            @include media(xl) {
                bottom: 179px;
                left: 65%;
            }

            @include media(xxl) {
                bottom: 100px;
                left: 68%;
                width: 46%;
            }

            @media screen and (min-width: 1420px) {
                bottom: 66px;
                width: 40%;
            }
        }
    }
</style>
