<template>
  <div class="option" :value="value" :target-element="targetElement" tabindex="0" @mouseup="changeState">
    <slot></slot>
  </div>
</template>


<script setup>
  import { inject } from 'vue'

  const emitter = inject('emitter') // Inject `emitter`

  const props = defineProps({
    value: {
      type: String,
      default: '',
    },

    targetElement: {
      type: String,
      default: '',
    },
  })

  function changeState() {
    if (props.targetElement) {
      emitter.emit('changeState', props.targetElement)
    }
  }
</script>
