<template>
    <section
        style="padding-top: 5rem; padding-bottom: 5rem"
        id="mapa"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                    <h2
                        class="text-grey-300 text-center text-md-start mb-5 text-uppercase"
                    >
                        Localize nossos credenciados
                    </h2>
                    <p class="text-center text-md-start">
                        Encontre as nossas redes credenciadas de forma fácil e
                        rápida, clique no marcador e veja o endereço.
                    </p>
                </div>
                <div class="col-12 col-md-8">
                    <div class="map-wrapper">
                        <div class="map"></div>
                        <div class="--overlay">
                            Clique no mapa para interagir.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { onMounted } from 'vue'
    import '/libs/leaflet-1-9-1/leaflet171/leaflet171.js'

    const props = defineProps({
        coordinates: {
            type: Array,
        },
    })

    onMounted(() => {
        const map = document.querySelector('.map')

        let info = props.coordinates

        let paraTodosLogo = L.icon({
            iconUrl: '/img/icons/pin-meu-cartao-para-todos.png',
            iconSize: [35, 50], // size of the icon
            iconAnchor: [50, 50], // point of the icon which will correspond to marker's location
            popupAnchor: [-30, -50], // point from which the popup should open relative to the iconAnchor
        })

        // initialize the map on the "map" div with a given center and zoom
        let myMap = L.map(map).setView([-1.4038975, -48.3943653], 12.41)

        info.forEach(el => {
            L.marker([el.lon, el.lat], {
                icon: paraTodosLogo,
            }).addTo(myMap).bindPopup(`
              <div style="margin-bottom: 10px">
                <img src="${el.image}" style="width: 50px" />
              </div>

              <div>
                <strong>${el.title}</strong>
              </div>
              <div>
                <strong>${el.subtitle}</strong>
              </div>

              <div class="mt-4">
                <button class="btn btn-primary" onclick="window.open('https://www.google.com/maps/dir/?api=1&origin=&destination=${el.lon},${el.lat}', '_blank')">Como chegar?</button>
              </div>
            `)
        })

        L.tileLayer(
            'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYnJlZGl3ZWIiLCJhIjoiY2xpdzE4aG8yMHBrMDNzcGlyeDVhaW5qdiJ9.MlwKIQrpU15Lpw_x29xbXA',
            {
                maxZoom: 18,
                attribution: `Map data &copy; contribuidores do <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>,
    Imagery © <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>`,
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1,
                tap: false,
            }
        ).addTo(myMap)

        setTimeout(function () {
            window.dispatchEvent(new Event('resize'))
        }, 1000)
        // Prevents scrolling before interaction
        ;(function () {
            const mapWrapper = document.querySelector('.map-wrapper')

            if (mapWrapper) {
                const map = mapWrapper.querySelector('.map')
                const overlay = mapWrapper.querySelector('.--overlay')
                const icons = mapWrapper.querySelectorAll(
                    '.leaflet-marker-pane img'
                )

                map.style.pointerEvents = 'none'
                map.style.opacity = '.6'

                icons.forEach(el => {
                    el.style.pointerEvents = 'none'
                })

                // Disable scroll zooming and bind back the click event
                let onMapMouseleaveHandler = e => {
                    e.target.addEventListener('click', onMapClickHandler)

                    map.removeEventListener('mouseleave', () => {
                        onMapMouseleaveHandler()
                        onMapScroll()
                    })

                    map.style.pointerEvents = 'none'
                    map.style.opacity = '.6'

                    icons.forEach(el => {
                        el.style.pointerEvents = 'none'
                    })
                }

                let onMapClickHandler = e => {
                    // Disable the click handler until the user leaves the map area
                    e.target.removeEventListener('click', onMapClickHandler)

                    // Enable scrolling zoom
                    map.style.pointerEvents = 'auto'
                    map.style.opacity = ''

                    icons.forEach(el => {
                        el.style.pointerEvents = 'auto'
                    })

                    // Handle the mouse leave event
                    map.addEventListener('mouseleave', onMapMouseleaveHandler)
                }

                let onMapScrollOverHandler = () => {
                    overlay.style.opacity = '1'

                    setTimeout(() => {
                        overlay.style.opacity = ''
                    }, 2000)
                }

                // Enable map zooming with mouse scroll when the user clicks the map
                mapWrapper.addEventListener('mousedown', onMapClickHandler)
                mapWrapper.addEventListener('wheel', onMapScrollOverHandler)
            }
        })()
    })
</script>
