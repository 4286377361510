<template>
  <div class="table" ref="table">
    <slot></slot>
  </div>
</template>


<script setup>
  import { ref, onMounted, reactive } from 'vue'

  const table = ref(null)
  let tdLength

  defineProps({
    columns: {
      type: Number,
      default: 4
    }
  })

  const data = reactive({
    columns: null
  })

  onMounted(() => {
    const th = table.value.querySelector('.th')
    const td = th.querySelectorAll('.td')
    data.columns = td.length

    tdLength = data.columns
  })
</script>


<style lang="scss">
  @import '@sass/global/mixins';
  @import '@sass/global/functions';

  $columns: v-bind('data.columns');

  .table {
    margin: 0 0 size(40) 0;
    width: 100%;
    display: block;

    @include media(lg) {
      display: flex;
      flex-flow: row wrap;
    }

    .th {
      display: none;
      padding: 0;
      height: 6px;
      margin-bottom: size(8);
      position: sticky;
      top: size(8);
      z-index: 1;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: color(--white);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: size(8);
        box-shadow: 0 size(1) 3px rgba(#000, 20%);
        z-index: -1;
      }

      .td {
        display: none;
      }

      @include media(lg) {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        font-weight: 900;
        color: color(--grey-300);
        height: auto;

        .td {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          align-content: center;
          flex-wrap: nowrap;
          width: calc(100% / $columns);
          padding: size(8) size(24);
        }
      }
    }

    .tr {
      padding: size(8) 0;
      display: block;
      background-color: #fff;
      margin-bottom: size(16);
      box-shadow: 2px 3px 5px rgba(#000, 12%);
      border-radius: size(8);
      border-top: size(8) solid color(--green-light-100);

      .td {
        display: block;
        padding: size(24);
        border-bottom: size(1) solid color(--green-light-100);

        &:last-of-type {
          border-bottom: none;
        }

        &::before {
          content: attr(data-title);
          display: block;
          margin-bottom: size(10);
          min-width: size(98);
          font-size: size(10);
          line-height: size(10);
          font-weight: bold;
          text-transform: uppercase;
          color: color(--grey-200);

          &:last-of-type {
            display: none;
          }
        }

        @include media(lg) {
          display: flex;
          align-items: center;
          justify-content: center;
          align-content: center;

          &::before {
            content: attr(data-title);
            display: block;
            margin-bottom: size(10);
            min-width: size(98);
            font-size: size(10);
            line-height: size(10);
            font-weight: bold;
            text-transform: uppercase;
            color: color(--grey-100);
          }
        }
      }

      @include media(lg) {
        display: flex;
        width: 100%;
        background-color: transparent;
        margin: 0;
        padding: 0;
        box-shadow: none;
        border-top: none;
        color: color(--grey-200);

        .td {
          width: calc(100% / $columns);

          &:last-of-type {
            border-bottom: size(1) solid color(--green-light-100);
          }

          &::before {
            display: none;
          }
        }
      }
    }

    .btn {
      padding: size(12) size(16);
    }
  }
</style>


