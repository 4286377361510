<template>
    <div class="swiper swiperBlogBanner">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <div class="swiper-button-prev swiper-blog-banner-button-prev"></div>
        <div class="swiper-button-next swiper-blog-banner-button-next"></div>
    </div>
</template>

<script setup>
    import Swiper, { Navigation, A11y, Autoplay } from 'swiper'
    import { onMounted } from 'vue'

    // Import Swiper styles
    import 'swiper/css'
    import 'swiper/css/navigation'

    onMounted(() => {
        const swiperBanner = new Swiper('.swiperBlogBanner', {
            // configure Swiper to use modules
            // autoplay: {
            //     delay: 4500,
            // },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                992: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
            modules: [Navigation, A11y, Autoplay],
            spaceBetween: 72,
            navigation: {
                nextEl: '.swiper-blog-banner-button-next',
                prevEl: '.swiper-blog-banner-button-prev',
            },
            slidesPerView: 3,
        })
    })
</script>

<style lang="scss">
    @import '@sass/global/functions';

    .swiperBlogBanner {
        width: 100%;
        height: 100%;
        padding: 1.25rem 0 1.25rem 0;
        top: 70px;
        margin-bottom: 70px;

        @media screen and (min-width: 992px) {
            top: 0;
            margin-bottom: 0;
        }

        .swiper-blog-banner-button-prev,
        .swiper-blog-banner-button-next {
            color: color(--white);
            filter: drop-shadow(2px 2px 5px black);

            @media (max-width: 575.98px) {
                top: 40%;
            }

            &::after {
                font-size: 30px;
                font-weight: bold;

                @media (max-width: 575.98px) {
                    font-size: 20px;
                }
            }
        }
    }
</style>
